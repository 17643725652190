@font-face
{
    font-family: 'Ailerons';
    src: url('./fonts/subset-Ailerons-Regular.woff2') format('woff2'),
        url('./fonts/subset-Ailerons-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*::selection
{
    /* color: #aaaaaa; */
    background: #ffffff22;
}

html {
    
    background: #130521;
}

body
{
    overflow: scroll;
}

.webgl
{
    z-index: -1000;
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}
/* 

.menu
{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.menu .bar
{
    width: 80%;
    height: 2px;
    margin-bottom: 6px;
    background: #ffffff;
} */

/* ///////////..utility classes../////////// */

.container {
    max-width: 1200px;
    width: 90%;
    margin: auto;
    
  }
  
  .btn {
    display: inline-block;
    padding: 0.5em 1.5em;
    text-decoration: none;
    border-radius: 50px;
    cursor: pointer;
    outline: none;
    margin-top: 1em;
    text-transform: uppercase;
    font-weight: small;
  }
  
  .btn-primary {
    color: #fff;
    background: #16a08399;
  }
  .btn-secondary {
    color: #fff;
    background: #00000099;
  }

  .btn-primary:hover , .btn-secondary:hover  {
    background: #00000055;
    transition: background 0.3s ease-in-out;
  }
  
 .btn-secondary:hover  {
    background: #11796455;
    transition: background 0.3s ease-in-out;
  }
  
  /* ............/navbar/............ *
  
  /* desktop mode............/// */
  
  .navbar input[type="checkbox"],
  .navbar .hamburger-lines {
    display: none;
  }
  
  .navbar {
    box-shadow: 0px 5px 10px 0px #aaa;
    position: sticky;
    top: 0;
    width: 100%;
    background: #130621ff;
    color: #000;
    opacity: 0.85;
    height: 60px;
    z-index: 12;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 64px;
    align-items: center;
  }
  
  .menu-items {
    order: 2;
    display: flex;
  }
  
  .menu-items li {
    list-style: none;
    margin-left: 1.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-size: 1.2rem;
  }
  
 .logo, .menu-items a {
    transition: color 0.3s ease-in-out;
    color: #fff;
    font-size: 1.7rem;
    font-weight: 900;
    line-height: 40px;
    text-decoration: none;
    font-family: 'Ailerons', Helvetica, Arial, sans-serif;
    outline: none;
  }
  
  .menu-items a:hover {
    color: #117964;
    transition: color 0.3s ease-in-out;
  }
  
  .logo {
    order: 1;
    font-size: 2.9rem;
    margin-bottom: 0.5rem;
  }
  
  /* ............//// Showcase styling ////......... */
  
  .showcase-area {
    height: 100vh;
    /* background: linear-gradient(
        rgba(240, 240, 240, 0.144),
        rgba(116, 17, 17, 0.336)
      ),
      url("https://i.postimg.cc/wT3TQS3V/header-image2.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
  }
  
  .showcase-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 1.6rem;
  }
  
  .title {
      text-transform: uppercase;
      font-family: 'Ailerons', Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
      color: #ffffff;
      text-align: center;
      width: 100%;
      outline: none;
    }
  .main-title {
    font-size: 14vmin;
    font-weight: 900;
}

.sub-title {
    font-size: 8vmin;
    font-weight: 500;
}
  /* ......//about us//...... */
  
  #about {
    min-height: 100vh;
    padding: 50px 0;
    background: #f5f5f750;
    overflow: hidden;
    
  }
  
  .about-wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    /* flex-wrap: wrap; */
  }
  
  #about h2 {
    
    font-size: 2.3rem;
  }
  
  #about p {
    font-size: 1.2rem;
    /* color: #555; */
  }
  
  #about .small, .cards .small {
    text-transform: uppercase;
    font-family: 'Ailerons', Helvetica, Arial, sans-serif;
    font-size: 1.8rem;
    color: #fff;
    font-weight: 800;
  }
  
  .about-img {
    flex: 1 1 400px;
    padding: 30px;
    width: 120%;
    animation: about-img-animation 1s ease-in-out forwards;
  }
  
  .about-text {
    font-family: 'Poppins', sans-serif, Georgia, 'Times New Roman', Times, serif;
    font-size: 1rem;
    color: #130521;
    flex: 1 1 400px;
    padding: 30px;
    margin: auto;
    animation: about-text-animation 1s ease-in-out forwards;
  }

  .about-img img {
    display: block;
    height: 400px;
    max-width: 100%;
    margin: auto;
    object-fit: cover;
    object-position: right;
  }
  
  /* Services */

#features {
    box-sizing: unset;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 0 auto; */
    background-color: rgba(128, 128, 128, 0.22);

}

.features-container {
    width: 70%;
    /* background-color: #000; */
    /* height: 70%; */
    /* margin-top: 40px; */
}

.section-title {
    font-family: 'Ailerons', Helvetica, Arial, sans-serif;
    color: rgb(201, 78, 22);
    font-weight: 900;
    font-size: 2em;
}

#features h2 {
    margin-top: 90px;
    /* margin-bottom: 60px; */
    /* text-align: right; */
}

#features ul {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: space-around;
    list-style: none;
    margin: 0;
    padding: 0;
}

#features li {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: rgba(3, 169, 244, 0.1); */
    width: 25%;
    align-items: stretch;

    margin: auto;
    text-align: center;
}

#features li img {
    width: 100%;
    /* background-color: #666; */
    /* height: 75px; */
    /* margin-bottom: 25px; */
} 

#features li #img2{
    width: 100%;
}

#features li h3 {
    font-size: 1.5em;
    margin-bottom: 15px;
}

#features li p {
    font-size: 1em;
    line-height: 1.5;
}
  
  /* ........./ Testimonial /.......... */
  
  #testimonials {
    padding: 5rem 0;
    background: rgba(22, 82, 25, 0.59);
  }
  
  .testimonial-title {
    text-align: center;
    font-size: 2.8rem;
    font-weight: 400;
    color: rgb(255, 255, 255);
  }
  
  .testimonial-container {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    padding: 1rem;
  }
  
  .testimonial-box .checked {
    color: #ff9529;
    padding: 1rem;
  }
  
  .testimonial-box .checked:hover {
    color: #9595ff;
  }
  
  .testimonial-box .testimonial-text {
    margin: 1rem 0;
    color: #fff;
    font-weight: 700;
  }
  
  .testimonial-box {
    text-align: center;
    padding: 1rem;
  }
  
  .customer-photo p {
    color: #ff9529;
    font-weight: 700;
  }

  .customer-photo img {
    display: block;
    width: 150px;
    height: 150px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    margin: auto;
  }
  
  /* ........ Contact Us........... */
  
  #contact {
    padding: 5rem 0;
    background: rgba(226, 226, 226, 0.4);
  }
  
  .contact-container {
    display: flex;
    background: #ffffff17;
  }
  
  .contact-img {
    width: 50%;
  }
  
  .contact-img img {
    display: block;
    height: 400px;
    width: 100%;
    object-position: center;
    object-fit: cover;
  }
  
  .form-container {
    padding: 1rem;
    width: 50%;
    margin: auto;
  }
  
  .form-container input {
    display: block;
    width: 100%;
    border: none;
    border-bottom: 2px solid #ddd;
    padding: 1rem 0;
    box-shadow: none;
    outline: none;
    margin-bottom: 1rem;
    color: #444;
    font-weight: 500;
  }
  
  .form-container textarea {
    display: block;
    width: 100%;
    border: none;
    border-bottom: 2px solid #ddd;
    color: #444;
    outline: none;
    padding: 1rem 0;
    resize: none;
  }
  
  .form-container h2 {
    font-size: 2.7rem;
    font-weight: 500;
    color: #fff;
    margin-bottom: 1rem;
    margin-top: -1.2rem;
  }
  
  .form-container a {
    font-size: 1.3rem;
  }
  
  #footer {  
    background: rgba(165, 65, 65, 0.5);
  }
  
  #footer h2 {
    text-align: center;
    font-size: 1.2rem;
    padding: 2.6rem;
    font-weight: 500;
    color: #fff;
  }
  
  
.icons-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 5vh;
}
.icons{
    width: 50px;
    height: 50px;

    background: rgba(155, 155, 255, 0.444);
    border-radius: 10px;

    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.205),
    inset 0 20px 20px rgba(253, 253, 253, 0.205);   
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 2em;
    color: rgba(255, 255, 255, 0.336);
    position: relative;

    transition: box-shadow .4s, color .8s, transform .4s;
}
.icons:hover{
    color: #ffffff;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.205),
                inset 0 20px 10px rgba(255, 255, 255, 0.287),
                inset 10px 0 20px rgb(255, 58, 58),
                inset -20px 0 20px rgb(104, 255, 58),

                inset -30px 0 20px rgb(71, 58, 255);
    transform: translateY(-10px);
}
  
  /* ......../ media query /.......... */
  
  @media (max-width: 768px) {
    .navbar {
      opacity: 0.95;
    }
  
    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines {
      display: block;
    }
  
    .navbar-container {
      display: block;
      position: relative;
      height: 64px;
    }
  
    .navbar-container input[type="checkbox"] {
      position: absolute;
      display: block;
      height: 32px;
      width: 30px;
      top: 20px;
      left: 20px;
      z-index: 5;
      opacity: 0;
    }
  
    .navbar-container .hamburger-lines {
      display: block;
      height: 23px;
      width: 35px;
      position: absolute;
      top: 17px;
      left: 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  
    .navbar-container .hamburger-lines .line {
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      background: #fff;
    }
  
    .navbar-container .hamburger-lines .line1 {
      transform-origin: 0% 0%;
      transition: transform 0.4s ease-in-out;
    }
  
    .navbar-container .hamburger-lines .line2 {
      transition: transform 0.2s ease-in-out;
      margin-left: 10px;
    }
  
    .navbar-container .hamburger-lines .line3 {
      transform-origin: 0% 100%;
      transition: transform 0.4s ease-in-out;
    }
  
    .navbar .menu-items {
      padding-top: 100px;
      background: #13052188;
      height: 100vh;
      max-width: 250px;
      transform: translate(-150%);
      display: flex;
      flex-direction: column;
      margin-left: -40px;
      padding-left: 20px;
      transition: transform 0.5s ease-in-out;
      box-shadow: 5px 0px 10px 0px #aaa;
    }
  
    .navbar .menu-items li {
      margin-bottom: .7rem;
      font-size: 1.3rem;
      font-weight: 500;
    }
  
    .logo {
      position: absolute;
      top: 5px;
      right: 15px;
      font-size: 2rem;
    }
  
    .navbar-container input[type="checkbox"]:checked ~ .menu-items {
      transform: translateX(0);
    }
  
    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
      transform: rotate(35deg);
    }
  
    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
      transform: scaleY(0);
    }
  
    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
      transform: rotate(-35deg);
    }
  
    /* ......./ feature /......... */
    
    .feature-container ul{
        display: grid;
        grid-template-columns: 1fr;
      align-items: right;
    }
    .features-container{
        /* width: 100%; */
        background-color: #117964;
        margin: 0;
    }
    .features-container img{
        /* width: 200%; */
        background-color: rgb(185, 25, 25);
    }
    
    .about-wrapper{
      grid-template-columns: 1fr;
    }
    
    .about-img, .contact-img {
      display: none;
    }
    
    .con
    
    .about-text{
      align-items: stretch;
    }
  
  }
  
  @media (max-width: 500px) {
    html {
      font-size: 65%;
    }
  
    .navbar .menu-items li{
        font-size: 1.6rem;
    }
  
    .testimonial-container {
      flex-direction: column;
      text-align: center;
    }
  
    .food-menu-container img {
      margin: auto;
    }
  
    .food-menu-item {
      flex-direction: column;
      text-align: center;
    }
  
    .form-container {
      width: 90%;
    }
  
    .contact-container {
      display: flex;
      flex-direction: column;
    }
  
    .contact-img {
      width: 90%;
      margin: 3rem auto;
    }
  
    .logo {
      position: absolute;
      top: 06px;
      right: 15px;
      font-size: 3rem;
    }
  
    .navbar .menu-items li {
      margin-bottom: 2.5rem;
      font-size: 1.8rem;
      font-weight: 500;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1200px) {
    .img-container h3 {
      font-size: 1.5rem;
    }
  
    .img-container .btn {
      font-size: 0.7rem;
    }
  }
  
  @media (orientation: landscape) and (max-height: 500px) {
    .showcase-area {
      height: 50vmax;
    }
  }

#services {
    padding-top: 70px;
    min-height: 80vh;
    
}
.cards {
    margin: .25rem 8% 0;
}

.cards img{
    width: 50px;
}

.cards p {
    font-size: 1rem;
    font-weight: 500;
    color: #130521;
    font-family: 'Poppins', sans-serif, Georgia, 'Times New Roman', Times, serif;
}

.card-supervisor {
    border-radius: .5rem;
    padding: .5rem 2rem;
    margin-bottom: 1rem;
    background-color: #ffffff7f;
    box-shadow: 0 1rem 2rem .5rem #dedede55;
    border-top: .5rem solid hsl(180, 62%, 55%);
}

.card-team-builder {
    border-radius: .5rem;
    padding: .5rem 2rem;
    margin-bottom: 1rem;
    background-color: #ffffff7f;
    box-shadow: 0 1rem 2rem .5rem #dedede55;
    border-top: .5rem solid hsl(0, 78%, 62%);
}

.card-karma {
    border-radius: .5rem;
    padding: .5rem 2rem;
    margin-bottom: 1rem;
    background-color: #ffffff7f;
    box-shadow: 0 1rem 2rem .5rem #dedede55;
    border-top: .5rem solid hsl(34, 97%, 64%);
}

.card-calculator {
    border-radius: .5rem;
    padding: .5rem 2rem;
    margin-bottom: 1rem;
    background-color: #ffffff7f;
    box-shadow: 0 1rem 2rem .5rem #dedede55;
    border-top: .5rem solid hsl(212, 86%, 64%);
}

.card-title {
    margin: 0;
    font-size: 2rem;
    color: #130521;
    font-family: 'Ailerons', Helvetica, Arial, sans-serif;
    font-weight: 900;
}

.card-paragraph {
    margin: 0;
    font-size: 1.3rem;
    color: hsl(229, 6%, 66%);
}

.card-image {
    /* padding-top: 3rem; */
    display: flex;
    width: 5rem;
}

.attribution {
    margin-top: 5rem;
    font-size: 1rem; 
    text-align: center;
    font-weight: 300;
}

.attribution a {
    font-weight: 600;
    text-decoration: none;
    color: hsl(228, 45%, 44%); 
}

@media (min-width: 680px) {
    .header-paragraph {
        font-size: 1.4rem;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 3rem;       
    }
}

@media (min-width: 1250px) {
    .header-paragraph {
        font-size: 1.3rem;
    }
    
    .cards {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas: 
        "start mid1 end"
        "start mid2 end";       
    }

    .card-supervisor  {
        grid-area: start;
        align-self: center;
    }

    .card-team-builder {
        grid-area: mid1;
    }
    .card-karma {
        grid-area: mid2;
    }
    .card-calculator {
        grid-area: end;
        align-self: center;
    }

}
